import React, { useState, useEffect } from 'react';
import './Home.css'
import { Link } from 'react-router-dom';

function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeModalIndex, setActiveModalIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      // Disable scrolling on the body when the modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling on the body when the modal is closed
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  const openModal = (index) => {
    console.log("Opening modal for index:", index);
    setIsModalOpen(true);
    setActiveModalIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveModalIndex(null);
  };

  const servicesData = [
    {
      title: 'Hogar, Oficinas e Indústrias',
      content: 
      <div className="flex">
        <div className="services1-img1 services-img"/>
        <div className='modal-text'>
          <h1>Hogar, oficinas e industrias</h1>
          <li>Reformas integrales</li>
          <li>Modificación de interiores</li>
          <li>Reparación de averías</li>
          <li>Instalaciones de calderas, calentadores y termos</li>
          <li>Instalaciones domóticas</li>
          
        </div>
      </div>,
    },
    {
      title: 'Comunidades',
      content:       
      <div className="flex">
        <div className="services2-img1 services-img"/>
      <div className='modal-text'>
        <h1>Comunidades</h1>
        <li>Mantenimiento de instalaciones</li>
        <li>Reparacion de averías de luz, gas y agua</li>
        <li>Renovación de instalaciones comunitarias</li>
        <li>Instalaciones de calderas, calentadores y termos</li>
        <li>Instalaciones domóticas</li>
        
      </div>
    </div>,
    },
    // Add more services data here
  ];

  return (
    <div className="homePage">
      <section className="introSection">
        <h1 className="slogan">Siempre a su servicio.</h1>
        <p className="companyLargeIntro">
          Desde nuestro inicio en el año 2000, brindamos servicios de lampistería, fontanería y reformas entre otras a la ciudad de Barcelona y sus alrededores con un enfoque constante en la satisfacción del cliente y el cumplimiento de sus objetivos y presupuesto.
          <br/> 
          Con más de 20 años de experiencia, compromiso y clientes satisfechos, estamos listos para ayudarte a hacer realidad tus proyectos con profesionalismo y dedicación.
        </p>
        <Link to="/contact">
          <button className="contactButton">Contáctanos</button>
        </Link>
      </section>

      <section className="imageTransitions">
        <div
          className="imageTransitionContainer1"
          style={{ transform: `translateY(-${scrollPosition * 0.1}px)` }}
        />
        <div
          className="imageTransitionContainer2"
          style={{ transform: `translateY(-${scrollPosition * 0.8}px)` }}
        />
      </section>

      <div className="anchorBox" id="servicios"></div>

      <section className="servicios">
        <h2>Nuestros servicios</h2>

        <div className="grid">
          {servicesData.map((service, index) => (
            <div key={index} className="card">
              <h3>{service.title}</h3>
              <button className="open-modal-btn" onClick={() => openModal(index)}>
                Saber más
              </button>
            </div>
          ))}
        </div>

        {isModalOpen && activeModalIndex !== null && (
          <div className="modal-container open">
            <div className="modal-content">
              <button className="close-modal-btn" onClick={closeModal}>X</button>
              <div className="modal-text">
                <p>{servicesData[activeModalIndex].content}</p>
              </div>
            </div>
          </div>
        )}
      </section>


      <div className="InfoFlashcards">
        <section class="flashcardBox flexFlashcardBox WWUbox">
          <div class="generalCardSettings proyectosBg">
            <div class='overlay-content'>
              <h2>Inspírate con <br/>nuestros proyectos.</h2>
              <a href="/proyectos">
                <button>Ver proyectos</button>
              </a>
            </div>
          </div>
        </section>

        <section className="flashcardBox flexFlashcardBox">
          <div className="generalCardSettings workBg">
            <div>
              <h2>Pide tu presupuesto<br/>ahora!</h2>
              <a href="/contact">
                <button>Contacto</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
