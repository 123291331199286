import React, { useState, useEffect } from "react";
import imagesData from "./images.json";
import "./proyectos.css"; // Import the CSS file

const Proyectos = () => {
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    // Simulating fetching images from a JSON file
    setImages(imagesData.images);
  }, []);

  useEffect(() => {
    // Toggle body overflow property when showModal changes
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    // Close modal when changing category
    setShowModal(false);
  };

  const openModal = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  const viewFullscreen = () => {
    if (modalImage) {
      const newWindow = window.open(modalImage.url, '_blank');
      if (newWindow) newWindow.document.title = modalImage.id;
    }
  };

  // Determine filtered images based on selected category
  const filteredImages = selectedCategory === "Todos"
    ? images
    : images.filter((image) => image.category === selectedCategory);

  return (
    <div className="galleryPage">
      <div className="filterButtons">
        <button
          className={`filterButton ${selectedCategory === "Todos" ? "active" : ""}`}
          onClick={() => handleCategoryChange("Todos")}
        >
          Todos
        </button>
        <button
          className={`filterButton ${selectedCategory === "Cocinas" ? "active" : ""}`}
          onClick={() => handleCategoryChange("Cocinas")}
        >
          Cocinas
        </button>
        <button
          className={`filterButton ${selectedCategory === "Baños" ? "active" : ""}`}
          onClick={() => handleCategoryChange("Baños")}
        >
          Baños
        </button>
        <button
          className={`filterButton ${selectedCategory === "Oficinas" ? "active" : ""}`}
          onClick={() => handleCategoryChange("Oficinas")}
        >
          Oficinas
        </button>
        <button
          className={`filterButton ${selectedCategory === "Pisos" ? "active" : ""}`}
          onClick={() => handleCategoryChange("Pisos")}
        >
          Pisos
        </button>
        <button
          className={`filterButton ${selectedCategory === "Comunidades" ? "active" : ""}`}
          onClick={() => handleCategoryChange("Comunidades")}
        >
          Comunidades
        </button>
        <button
          className={`filterButton ${selectedCategory === "EN" ? "active" : ""}`}
          onClick={() => handleCategoryChange("EN")}
        >
          Energías Renovables
        </button>
      </div>
      <div className="gallerySection">
        {filteredImages.map((image) => (
          <div
            key={image.id}
            className="galleryItem"
            style={{ backgroundImage: `url(${image.url})` }}
            onClick={() => openModal(image)}
          >
          </div>
        ))}
      </div>

      {showModal && modalImage && (
        <div className="modalBackdrop" onClick={closeModal}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <button className="closeButton" onClick={closeModal}>X</button>
            <div className="modalCollabContainer">
              <div className="logo-icon" />
              <div className="modalImageContainer">
                <img className="modalImage" src={modalImage.url} alt=' ' />
                <button className="fullscreenButton" onClick={viewFullscreen}>⤢</button>
              </div>
              <div className="imageTitle">{modalImage.title}</div>
              <img className="imageCollab" src={modalImage.collab} alt=" " />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Proyectos;