import React from 'react';
import './privacy.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Privacy() {
  return (
    <section className="section-hero header-text-center">
      <div className="section-content privacy-container">
        <h1 className="privacy-title">Política de Privacidad de Instalec Jordán</h1>
        <h3 className="privacy-subtitle">Actualizado el 27 de junio de 2024</h3>
        <div className="privacy-content">
          <p>Las presente Política de Privacidad regula el tratamiento de datos personales facilitados por el Usuario a través del portal de Internet (en adelante, el "Portal") que INSTALEC JORDÁN, S.L. pone a disposición de los usuarios de Internet.</p>
        </div>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">Información Básica sobre Protección de Datos</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <h3>Responsable del Tratamiento de Datos</h3>
              <p><strong>Entidad:</strong> INSTALEC JORDÁN, S.L.</p>
              <p><strong>CIF:</strong> B62488432</p>
              <p><strong>Dirección:</strong> Carrer de Sant Gervasi de Cassoles, 11, 08022, Barcelona</p>
              <p><strong>Teléfono:</strong> 932 12 54 99</p>
              <p><strong>Correo Electrónico:</strong> info@instalecjordan.com</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">Finalidad del Tratamiento de Datos</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>INSTALEC JORDÁN, S.L. trata la información facilitada por el Usuario con el fin de atender las solicitudes de diversa índole llevadas a cabo por parte de este.</p>
              <p>En función de la naturaleza de la solicitud, la finalidad perseguirá la gestión de:</p>
              <ul>
                <li>Consultas y solicitudes de propuestas, (RFP) remitidas a través del formulario de contacto habilitado al efecto.</li>
                <li>Comunicaciones electrónicas de naturaleza informativa, de acuerdo con sus intereses.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">Tratamiento de datos para envíos de comunicaciones desde Instalec Jordán</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>En las comunicaciones informativas y/o promocionales son tratados por INSTALEC JORDÁN, S.L para finalidades consistentes en el envío electrónico de información y comunicaciones sobre servicios, actividades, publicaciones y acontecimientos y profesionales de INSTALEC JORDÁN, S.L. o del seguimiento y optimización de las campañas de marketing realizadas mediante tecnologías al efecto; y la elaboración de perfiles con finalidades comerciales.</p>
              <p>El consentimiento para el envío de dichas comunicaciones podrá ser revocado en todo momento en cada una de las comunicaciones recibidas mediante el mecanismo habilitado al efecto.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Cuánto tiempo conservamos los datos?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>Con carácter general, los datos personales proporcionados se conservarán durante el tiempo necesario para atender la solicitud del Usuario o mientras no se solicite su supresión por el Usuario.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Cuál es la legitimación para el tratamiento de los datos personales del usuario?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>La base legal para el tratamiento de los datos personales del Usuario es la legitimación por consentimiento del Usuario.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿A qué destinatarios se comunicarán los datos personales del usuario?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>En aquellos casos en que el Usuario solicite, a través del web, propuestas de servicios, sus datos serán facilitados a aquellos encargados del tratamiento de INSTALEC JORDÁN, S.L.</p>
              <p>Así mismo, los datos podrán ser cedidos a administraciones y organismos públicos para el cumplimiento de obligaciones directamente exigibles a INSTALEC JORDÁN, S.L.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Cuáles son los derechos del usuario cuando nos facilita sus datos personales?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>El Usuario tiene derecho a obtener confirmación sobre si INSTALEC JORDÁN, S.L. trata datos personales que le conciernen, así como a acceder a sus datos personales, solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
              <p>En determinadas circunstancias, el Usuario podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente se conservarán para el ejercicio o la defensa de reclamaciones.</p>
              <p>En determinadas circunstancias y por motivos relacionados con su situación particular, el Usuario podrá oponerse al tratamiento de sus datos. INSTALEC JORDÁN, S.L. cesará en el tratamiento de los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
              <p>El Usuario podrá plantear las cuestiones que considere en relación a la presente Política así como ejercer sus derechos en los términos legalmente previstos debiendo para ello dirigir una comunicación mediante correo postal a: INSTALEC JORDAN, S.L., Carrer de Sant Gervasi de Cassoles, 11, 08022, Barcelona., o correo electrónico a: <a href="mailto:info@instalecjordan.com">info@instalecjordan.com</a> con indicación de la solicitud correspondiente y acompañado de copia del DNI o documento acreditativo de la identidad.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
}

export default Privacy;