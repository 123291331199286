import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './cookieBanner.css'; // Import the CSS file

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    // Check if the cookie '-Cstatus' exists
    const cookieStatus = getCookie('-CStatus');
    if (!cookieStatus) {
      setShowBanner(true); // Show banner if cookie does not exist
    } else {
      // Process cookie status here if needed
      console.log('Cookie -Cstatus exists:', cookieStatus);
    }
  }, []);

  const handleAcceptAll = () => {
    setCookie('-CStatus', '200', 365); // Set cookie for 365 days
    setShowBanner(false); // Hide banner
    setCookieAccepted(true); // Set cookie accepted state
  };

  const handleAcceptImprescindibles = () => {
    setCookie('-CStatus', '400', 365); // Set cookie for 365 days
    setShowBanner(false); // Hide banner
    setCookieAccepted(true); // Set cookie accepted state
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-banner">
          <div className="content">
            <div className="message">
              <p className='messageStrong'>Usamos cookies para mejorar nuestros servicios online.</p>
              <p>Lea nuestra <Link to="/legal/cookies" className='messageLink'>Politica de Cookies</Link> para más detalles.</p>
            </div>
            <div className="actions">
              <button onClick={handleAcceptAll} className="accept-all">Aceptar todas</button>
              <button onClick={handleAcceptImprescindibles} className="reject-non-essential">
                Denegar no esenciales
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8" fill="none">
                  <path d="M0.292892 0.292894C0.683416 -0.0976306 1.31658 -0.0976315 1.70711 0.292892L7.00002 5.58579L12.2929 0.292894C12.6834 -0.0976306 13.3166 -0.0976315 13.7071 0.292892C14.0976 0.683416 14.0976 1.31658 13.7071 1.70711L7.70713 7.70711C7.51959 7.89464 7.26524 8 7.00002 8C6.7348 8 6.48045 7.89464 6.29291 7.70711L0.292894 1.70711C-0.0976306 1.31658 -0.0976315 0.683419 0.292892 0.292894Z" fill="currentColor" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {cookieAccepted && (
        <div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;