import React from 'react';
import './cookies.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Privacy() {
  return (
    <section className="section-hero header-text-center">
      <div className="section-content privacy-container">
        <h1 className="privacy-title">Política de Cookies de Instalec Jordán</h1>
        <h3 className="privacy-subtitle">Actualizado el 27 de junio de 2024</h3>
        <div className="privacy-content">
            <p>INSTALEC JORDÁN, S.L., titular de este Sitio Web, utiliza cookies propias y de terceros para distintas finalidades. INSTALE JORDÁN, S.L. recurre al uso de cookies a los efectos de cumplir con las exigencias legales previstas en la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI).</p>
            <p>La presente Política de Cookies tiene por objeto informarle de manera clara, precisa y completa sobre las cookies que utilizamos, la información que recopilamos a través de estas y para qué finalidades se utiliza dicha información tal y como establece el artículo 22.2 de la
            LSSI</p>.
        </div>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Qué son y cual es su finalidad?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
                <p>Una "cookie" es un archivo que se almacena en el ordenador, tablet, smartphone o cualquier otro dispositivo del usuario, con la finalidad de recabar y almacenar información acerca del uso, por parte de este último, del Sitio Web (artículo 22.2 LSSI).</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Quién es el responsable?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>Las cookies utilizadas en la página web son tratadas por INSTALEC JORDÁN, S.L. o terceros con sus finalidades y periodo de conservación, establecidos en el apartado cuarto de la presente política de cookies.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Qué tipo de cookies existen?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>A continuación, se expone una clasificación de las cookies que existen y pueden llegar a ser utilizadas en nuestro Sitio Web, teniendo en cuenta que una misma cookie puede estar incluida en más de una categoría.</p>
                <ul>
                    <p>1) Según la <strong>entidad que las gestiona</strong></p>
                        <li>Cookies propias: aquellas que se envían a su equipo desde nuestros propios equipos o dominios y desde el que prestamos el servicio que nos solicita.</li>
                        <li>Cookies de terceros: aquellas que se envían a su equipo desde un equipo o dominio que no es gestionado por nosotros, sino por otra entidad colaboradora.</li>

                    <p>2) Según el <strong>plazo de tiempo que permanecen activas</strong></p>
                        <li>Cookies de sesión: son aquellas diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (por ejemplo, una lista de productos adquiridos) y desaparecen al terminar la sesión.</li>
                        <li>Cookies persistentes: son aquellas en las que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>

                    <p>3) Según la <strong>finalidad</strong></p>
                        <li>Cookies técnicas: son aquellas que permiten la navegación a través del Sitio Web, incluyendo aquellas utilizadas para la gestión del Sitio Web y la habilitación de sus funciones y servicios (p. ej.: controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, gestionar el pago, etc.). Estas cookies estarán exceptuadas del cumplimiento de las obligaciones establecidas en el artículo 22.2 de la LSSI cuando permitan prestar el servicio solicitado por el usuario. Sin embargo, si estas cookies se utilizan para finalidades no exentas, quedarán sujetas a dichas obligaciones.</li>
                        <li>Cookies de personalización: son aquellas que permiten recordar información para que pueda acceder al Sitio Web con determinadas características que pueden diferenciar su experiencia de la de otros usuarios (p.ej.: el idioma, el número de resultados a mostrar cuando el usuario realiza una búsqueda, el aspecto o contenido del servicio en función del tipo de navegador a través del cual el usuario accede al servicio o de la región desde la que accede al servicio, etc.). Si es el propio usuario quien elige esas características (por ejemplo, seleccionando el idioma del sitio web clicando sobre el icono de la bandera que corresponde), las cookies estarán exceptuadas de las obligaciones del artículo 22.2 de la LSSI por considerarse un servicio expresamente solicitado por el usuario, siempre y cuando las cookies obedezcan a la finalidad seleccionada.</li>
                        <li>Cookies de análisis o medición; son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios, incluida la cuantificación de los impactos de los anuncios. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad del Sitio Web con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios.</li>
                        <li>Cookies publicitarias o de publicidad: son aquellas que permiten la gestión de las publicaciones publicitarias o de publicidad de los sitios web de terceros.</li>
                </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Qué cookies utilizamos en nustro sitio web?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
                <p>INSTALEC JORDÁN, S.L. utiliza las siguientes cookies:</p>
                <table className="styled-table">
                <thead>
                    <tr>
                    <th>Tipo de Cookie</th>
                    <th>Nombre</th>
                    <th>Descripción / Finalidad</th>
                    <th>Propiedad</th>
                    <th>Caducidad</th>
                    <th>Transferencia Internacional</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Terceros</td>
                    <td>_ga</td>
                    <td>Cookie que se usa para realizar análisis del comportamiento del usuario</td>
                    <td>Instalec Jordán / Terceros <br/> Google</td>
                    <td>2 años</td>
                    <td>No</td>
                    </tr>
                    <tr>
                    <td>Propia</td>
                    <td>-CStatus</td>
                    <td>Cookies para el correcto funcionamiento y registrar preferencias de usuaria</td>
                    <td>Instalec Jordán</td>
                    <td>1 año</td>
                    <td>No</td>
                    </tr>
                </tbody>
                </table>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Durante cuánto tiempo conservamos la información?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>En cumplimiento del art. 13.2 del Reglamento (UE) 2016/679, mantendremos la información recabada a través de las cookies durante el tiempo imprescindible para cumplir con las finalidades indicadas en cada cookie.</p>
                <p>En relación con las cookies de terceros, los plazos de conservación podrán ser consultados en sus respectivas Políticas de Privacidad y de Cookies que constan en la tabla anterior.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Como configurar las cookies?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>La legitimación de INSTALEC JORDÁN, S.L. para el uso de cookies está basada en el consentimiento expreso que se le solicita, conforme al artículo 6.1.a) del Reglamento (UE)
2016/679.
El consentimiento que nos ha prestado es aplicable a las cookies del presente Sitio Web y de los sitios web de los terceros con los que colaboramos, especificados en el apartado "¿qué cookies utilizamos en nuestro sitio web?" de la presente Política.
Recuerde que en cualquier momento puede modificar su consentimiento a través del boton situado en la parte inferior de nuestro Sitio Web (Configurar Cookies).
Puede restringir, bloquear o borrar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador de Internet:
<br/>
- Google Chrome:<a href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform=Desktop&hl=est'>
    https://support.google.com/accounts/answer/61416?co=GENIE.Platform=Desktop&hl=est
    </a>
<br/>
- Mozilla Firefox: <a href='support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias'>
    support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
    </a>
<br/>
- Internet Explorer:  
https://support.microsoft.com/es-es/topic/eliminar-y-administrar:cookies-168dabl1-0753-043d-7cl6-ede5947fc64d

<br/>
- Safari: <a href='https://support.apple.com/es-es/guide/safari/sfril1471/mac'> 
https://support.apple.com/es-es/guide/safari/sfril1471/mac 
</a>
</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="privacy-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="privacy-accordion-summary"
          >
            <Typography variant="h5">¿Cuáles son los derechos del usuario cuando nos facilita sus datos personales?</Typography>
          </AccordionSummary>
          <AccordionDetails className="privacy-accordion-details">
            <Typography>
              <p>El Usuario tiene derecho a obtener confirmación sobre si INSTALEC JORDÁN, S.L. trata datos personales que le conciernen, así como a acceder a sus datos personales, solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
              <p>En determinadas circunstancias, el Usuario podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente se conservarán para el ejercicio o la defensa de reclamaciones.</p>
              <p>En determinadas circunstancias y por motivos relacionados con su situación particular, el Usuario podrá oponerse al tratamiento de sus datos. INSTALEC JORDÁN, S.L. cesará en el tratamiento de los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
              <p>El Usuario podrá plantear las cuestiones que considere en relación a la presente Política así como ejercer sus derechos en los términos legalmente previstos debiendo para ello dirigir una comunicación mediante correo postal a: INSTALEC JORDAN, S.L., Carrer de Sant Gervasi de Cassoles, 11, 08022, Barcelona., o correo electrónico a: <a href="mailto:info@instalecjordan.com">info@instalecjordan.com</a> con indicación de la solicitud correspondiente y acompañado de copia del DNI o documento acreditativo de la identidad.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
}

export default Privacy;