import React, { useEffect } from 'react';
import L from 'leaflet'; // Import Leaflet library
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import './contacto.css'; // Example CSS import

function Contacto() {
  useEffect(() => {
    // Initialize Leaflet map
    const map = L.map('map-container').setView([41.4042098598875, 2.13933196763005], 15); // Example coordinates and zoom

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Add marker and popup if needed
    L.marker([41.4042098598875, 2.13933196763005]).addTo(map)
      .bindPopup('Estamos Aqui')
      .openPopup();
  }, []);

  return (
    <div className="contacto-container">
      <div className="contacto">
        <h1>Contacto</h1>
        <ul>
          <li className="contacto-item">
            <div className="contacto-icon loc-icon" />
            <address className="contacto-text">C/ de Sant Gervasi de Cassoles, 11, 08022 Barcelona</address>
          </li>

          <li className="contacto-item">
            <div className="contacto-icon time-schedule-icon" />
            <div className="time-schedule-text">
              <p>Lunes a Viernes de 9:00 a 13:00 y de 16:00 a 20:00</p>
              <p>Sábado y Domingo cerrado</p>
            </div>
          </li>

          <li className="contacto-item">
            <div className="contacto-icon phone-icon" />
            <div className="phone-text">
              <a href='tel:932125499'>932 12 54 99</a>
            </div>
          </li>

          <li className="contacto-item">
            <div className="contacto-icon email-icon" />
            <div className="email-text">
              <a href='mailto:info@instalecjordan.com'>info@instalecjordan.com</a>
            </div>
          </li>

          <li className="contacto-item map-container" id="map-container">
            {/* Map will be rendered here */}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contacto;