import React from "react";
import { createRoot } from "react-dom";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from './components/footer';
import CookieB from './components/cookieBanner.js'
import "./App.css";

import Home from "./routes/home/Home.js";
import Proyectos from "./routes/proyectos/Proyectos";
import Contacto from "./routes/contacto/Contacto";
import Privacy from './routes/legal/pdPrivacidad/privacy.js'
import Cookies from './routes/legal/udCookies/cookies.js'

import ErrorPage from "./routes/errorPage/Error";

const AppLayout = () => {
  return (
    <>
      <Navbar />
      <CookieB />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="proyectos" element={<Proyectos />} />
        <Route path="contact" element={<Contacto />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="legal/privacidad" element={<Privacy />} />
        <Route path="legal/cookies" element={<Cookies />} />
      </Routes>
      <Footer />
    </>
  );
};

// Scroll to top when the location changes
function ScrollToTop() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

createRoot(document.getElementById("root")).render(
  <Router>
    <ScrollToTop />
    <AppLayout />
  </Router>
);
